import appStrings from '../../../../utility/string.utility';
import addEditFmsMeterReading from './addEditFmsMeterReading';
import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';

export default {
  name: 'fmsMeterReading',
  components: {
    addEditFmsMeterReading,
    DatePicker
  },
  watch: {
    currentPage: function() {
      this.getFmsMeterReadingData();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getFmsMeterReadingData();
    },
    selectAllCheckBox: function() {
      this.checkUncheckAll();
    }
  },

  data() {
    return {
      loader: false,
      project: {
        text: null,
        value: null
      },
      readingType: {
        text: null,
        value: null
      },
      entityType: {
        text: null,
        value: null
      },
      meterNumber: {
        text: null,
        value: null
      },
      billNumber: null,
      currentReadingDateFrom: null,
      currentReadingDateTo: null,
      previousReadingDateFrom: null,
      previousReadingDateTo: null,
      showValueSetModal: false,
      vsetCode: null,
      showMetermodal: false,
      totalRows: null,
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      fmsMeterReadingData: [],
      fmsMeterReadingFields: [
        {
          key: 'select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'bill_no',
          label: 'Bill Number'
        },
        {
          key: 'meter_no',
          label: 'Meter Number'
        },
        {
          key: 'reading_type',
          label: 'Reading Type'
        },
        // {
        //   key: 'trx_status'
        // },
        {
          key: 'previous_reading',
          label: 'Previous Reading '
        },
        {
          key: 'previous_reading_type',
          label: 'Previous Reading Date'
        },
        {
          key: 'current_reading',
          label: 'Current Reading'
        },
        {
          key: 'current_reading_type',
          label: 'Current Reading Date'
        }
        // {
        //   key: 'billing_status',
        //   label: 'Billing Status'
        // }
      ],
      rowId: null,
      readingFlag: null,
      meterReadingData: {},
      editMode: true,
      selectAllCheckBox: false
    };
  },

  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.rowId = null;
          this.showMetermodal = true;
        }
        if (actionName === 'delete') {
          this.deleteMeterReading();
        }
      }
    });
  },
  methods: {
    // checkUncheckAll() {
    //     this.meterReadingData = this.meterReadingData.map((data) => {
    //         data.select = data.status_flag !== 'PROCESSED' && this.selectAllCheckBox;
    //         return data;
    //     });
    // },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.parent_value_set_id = null;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.READING_TYPE) {
        (this.readingType.text = item.value_meaning),
          (this.readingType.value = item.value_code);
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_INTERNAL) {
        this.project.text = item.fms_prj_name;
        this.project.value = item.fms_prj_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.UNIT_TYPE) {
        this.entityType.text = item.value_meaning;
        this.entityType.value = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.METER_NO_VSET) {
        this.meterNumber.text = item.meter_no;
        this.meterNumber.value = item.meter_id;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === appStrings.VALUESETTYPE.READING_TYPE) {
        this.readingType.text = null;
        this.readingType.value = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_INTERNAL) {
        this.project.text = null;
        this.project.value = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.UNIT_TYPE) {
        this.entityType.text = null;
        this.entityType.value = null;
      }
    },
    showhideFmsMeterModal(flag) {
      this.showMetermodal = flag;
    },
    getFmsMeterReadingData() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        reading_type: this.readingType.value,
        meter_id: this.meterNumber.value,
        previous_reading_date_from: this.previousReadingDateFrom,
        previous_reading_date_to: this.previousReadingDateTo,
        current_reading_date_from: this.currentReadingDateFrom,
        current_reading_date_to: this.currentReadingDateTo
      };
      this.loader = true;
      this.$store
        .dispatch('masterData/getFmsMeterReadingData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.fmsMeterReadingData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected(item) {
      this.rowId = item.id;
      this.readingFlag = item.reading_type;
      this.getFmsMeterReadingDataById();
      setTimeout(() => {
        this.showMetermodal = true;
      }, 1000);
      this.editMode = false;
    },
    getFmsMeterReadingDataById() {
      const payload = {
        flag: this.readingFlag,
        id: this.rowId
      };
      this.loading = true;
      this.$store
        .dispatch('masterData/getFmsMeterReadingDataById', payload)
        .then(response => {
          if (response.status === 200) {
            this.meterReadingData = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    deleteMeterReading() {
      const promt = confirm(
        'Are you sure, you want to delete this Meter Upload?'
      );
      if (promt) {
        const filterData = this.fmsMeterReadingData.filter(data => data.select);
        const MetertUpload = filterData.map(elem => {
          return {
            id: elem.id,
            reading_type: elem.reading_type
          };
        });
        const payload = { meter: MetertUpload };
        this.loader = true;
        this.$store
          .dispatch('masterData/deleteMeterReading', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.editMode = false;
              this.showAlert = true;
              this.isSuccess = true;
              alert('Record Deleted Successfully');
              this.getFmsMeterReadingData();
            } else {
              this.showAlert = true;
              this.isSuccess = false;
              alert(response.response.data.message);
            }
          });
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
