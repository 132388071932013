import appStrings from '../../../../../utility/string.utility';
import DatePicker from 'vue2-datepicker';

export default {
  name: 'addEditFmsMeterReading',
  props: ['meterReadingData', 'rowId', 'readingFlag'],
  components: {
    DatePicker
  },
  data() {
    return {
      project: {
        text: null,
        value: null
      },
      readingType: {
        text: null,
        value: null
      },
      entityType: {
        text: null,
        value: null
      },
      meterNumber: {
        text: null,
        value: null
      },
      customerSite: {
        text: null,
        value: null
      },
      customerName: {
        text: null,
        value: null
      },
      entity: {
        text: null,
        value: null
      },
      currentReading: null,
      previousReading: null,
      currentReadingDate: null,
      previousReadingDate: null,
      showValueSetModal: false,
      vsetCode: null,
      billNumber: {
        text: null,
        value: null
      },
      parent_value_set_id: null,
      depVsetParam: null,
      showAlert: false,
      isSuccess: false,
      respMessage: null,
      loader: false,
      editMode: false
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          this.postFmsMeterReadingData();
        }
        if (actionName === 'update') {
            this.editMode = true;
          }
      }
    });
    if (this.rowId) {
      this.getDataFromParent(this.meterReadingData);
    } else {
        this.editMode=true;
    }
  },
  methods: {
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.parent_value_set_id = null;
      this.depVsetParam = null;
      this.showValueSetModal = true;
      if (this.vsetCode === appStrings.VALUESETTYPE.METER_NO_VSET) {
        this.parent_value_set_id = this.project.value;
        this.depVsetParam = this.entityType.value;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.BILL_NO_BY_METER_NO
      ) {
        this.parent_value_set_id = this.meterNumber.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.READING_TYPE) {
        (this.readingType.text = item.value_meaning),
          (this.readingType.value = item.value_code);
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_INTERNAL) {
        this.project.text = item.fms_prj_name;
        this.project.value = item.fms_prj_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.UNIT_TYPE) {
        this.entityType.text = item.value_meaning;
        this.entityType.value = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.METER_NO_VSET) {
        this.meterNumber.text = item.meter_no;
        this.meterNumber.value = item.meter_id;
        this.entity.text = item.unit_name;
        this.customerName.text = item.customer_name;
        this.customerName.value = item.customer_id;
        this.customerSite.text = item.customer_site_name;
        this.customerSite.value = item.customer_site_id;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.BILL_NO_BY_METER_NO
      ) {
        this.billNumber.text = item.document_num;
        this.billNumber.value = item.bill_hdr_id;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === appStrings.VALUESETTYPE.READING_TYPE) {
        this.readingType.text = null;
        this.readingType.value = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_INTERNAL) {
        this.project.text = null;
        this.project.value = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.UNIT_TYPE) {
        this.entityType.text = null;
        this.entityType.value = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.METER_NO_VSET) {
        this.meterNumber.text = null;
        this.meterNumber.value = null;
        this.entity.text = null;
        this.customerName.text = null;
        this.customerName.value = null;
        this.customerSite.text = null;
        this.customerSite.value = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.METER_NO_VSET) {
        this.billNumber.text = null;
        this.billNumber.value = null;
      }
    },
    postFmsMeterReadingData() {
      if (
        this.project.text === null ||
        this.readingType.text === null ||
        this.meterNumber.text === null ||
        this.previousReadingDate === null ||
        this.currentReadingDate === null ||
        this.previousReading === null ||
        this.currentReading === null ||
        this.billNumber.text === null
      ) {
        alert('Please Select Mandatory Fields');
      } else {
        const payload = {
          id: 0,
          reading_type: this.readingType.value,
          meter_id: this.meterNumber.value,
          previous_reading_date: this.previousReadingDate,
          current_reading_date: this.currentReadingDate,
          previous_reading: this.previousReading,
          current_reading: this.currentReading,
          bill_hdr_id: this.billNumber.value
        };
        this.loading = true;
        this.$store
          .dispatch('masterData/postFmsMeterReadingData', payload)
          .then(response => {
            if (response.status === 201) {
              this.showAlert = true;
              this.isSuccess = true;
              this.respMessage = response.data.message;
            } else {
              this.showAlert = true;
              this.isSuccess = false;
              this.respMessage = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    getFmsMeterReadingDataById() {
      const payload = {
        flag: this.readingFlag,
        id: this.rowId
      };
      this.loading = true;
      this.$store
        .dispatch('masterData/getFmsMeterReadingDataById', payload)
        .then(response => {
          if (response.status === 200) {
            this.project.text = this.meterReadingData.fms_prj_name;

            this.project.value = this.meterReadingData.fms_prj_id;
            this.readingType.text = this.meterReadingData.reading_type;
            this.meterNumber.text = this.meterReadingData.meter_no;
            this.meterNumber.value = this.meterReadingData.meter_id;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getDataFromParent(data) {
      this.project.text = data.fms_prj_name;
      this.project.value = data.fms_prj_id;
      this.readingType.text = data.reading_type;
      this.readingType.value = data.reading_type;
      this.entityType.text = data.entity_type;
      this.entityType.value = data.entity_id;
      this.meterNumber.text = data.meter_no;
      this.meterNumber.value = data.meter_id;
      this.entity.text = data.entity_name;
      this.customerName.text = data.customer;
      this.customerName.value = data.customer_id;
      this.customerSite.text = data.customer_site_name;
      this.customerSite.value = data.customer_site_id;
      this.previousReadingDate = data.previous_reading_type;
      this.previousReading = data.previous_reading;
      this.currentReadingDate = data.current_reading_type;
      this.currentReading = data.current_reading;
      this.billNumber.text = data.bill_no;
      this.billNumber.value = data.bill_hdr_id;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
